import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    emailService: "emailService"
  }
})
export default class EmailLayoutForm extends mixins(EntityForm) {
  model = {};

  get languagesOptions() {
    return this.languages.map((l) => {
      return {
        value: l.id,
        label: l.description,
      };
    });
  }

  get elements() {
    return [
      {
        id: "code",
        label: this.translations.labels.emailLayout_form_code,
        type: "text",
        required: true,
        rules: "min:4"
      },
      {
        id: "idLanguage",
        label: this.translations.labels.emailLayout_form_language,
        type: "select",
        items: this.languagesOptions,
        required: true,
      },
      {
        id: "bodyPlaceholders",
        type: "paragraph",
        text: this.translations.labels.emailLayout_form_bodyPlaceholders,
        resetColumns: true,
        columns: 2
      },
      {
        id: "contents",
        label: this.translations.labels.emailLayout_form_contents,
        hint: this.translations.labels.emailLayout_form_contents_hint,
        type: "html",
        required: true,
        resetColumns: true,
        columns: 2
      }
    ];
  }

  get helpLink() {
    return this.translations.pageHelp.configuration.email;
  }

  // eslint-disable-next-line no-empty-function
  afterCreate () {
  }

  async created() {
    this.languages = await this.languageService.list();
    this.afterCreate();
  }
}
